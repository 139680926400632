// FONTS
$font-primary: 'Roboto', sans-serif;
$font-display: 'Playfair Display', serif;


$font-sm: 1.4rem;
$font-md: 1.6rem;
$font-lg:1.8rem;
$font-xl:2.2rem;
$font-xxl:3rem;

// COLOR
$color-grey-4: #333;
$color-grey-3: #666;
$color-grey-2: #999;
$color-grey-1: #eee;

$color-white: #fff;

// BREAKPOINTS