.bg {
  background-image: url("../img/stainles-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}
.container {
}

.hero {
  padding-top: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  mix-blend-mode: multiply;

  &__logo {
    display: block;
    width: 20rem;
    height: 20rem;

    @media only screen and (max-width: 600px) {
      width: 15rem;
      height: 15rem;
    }
  }

  &__heading {
    font-size: $font-xxl;
    font-family: $font-primary;
    font-weight: 300;
    color: $color-grey-4;
    font-size: $font-xxl;

    @media only screen and (max-width: 600px) {
      font-size: $font-lg;
    }
  }
}

.cs-contact {
  font-family: $font-primary;
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: $font-md;
  @media only screen and (max-width: 600px) {
    font-size: $font-sm;
  }
  &__list {
    display: flex;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    a:link,
    a:visited {
      text-decoration: none;
    }

    a:hover > div {
      border-bottom: 2px solid #3b5998;
      transform: translateY(-0.2rem);
    }
  }

  &__item {
    border-bottom: 2px solid transparent;
    margin: 1rem;
    display: flex;
    align-items: baseline;

    padding-bottom: 0.4rem;
    transition: all 0.2s;
    svg {
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }
    p {
      color: #333;
    }
  }

  &__phone {
    color: green;
  }

  &__facebook {
    color: #3b5998;
  }
}
