*,
*::before,
*::after{
    padding:0;
    margin:0;
    box-sizing: inherit;
}

html{
    box-sizing: border-box;
}

body{
    font-family: $font-display;
    font-weight: 400;
    line-height: 1;
    font-size: 62.5%; // to make 1 rem = 10px;
}